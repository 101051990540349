import type { PaymentType } from '@tsTypes/PaymentType';

import PAYMENT_TYPES from '@constants/paymentTypes';
import useAppModuleConfig from '@hooks/useAppModuleConfig';

const useAvailablePaymentProviders = () => {
  const itemPaymentTypes = useAppModuleConfig('ItemPaymentTypes');

  const allowedPaymentTypes =
    'allowedPaymentTypes' in itemPaymentTypes
      ? itemPaymentTypes.allowedPaymentTypes
      : [];

  const isPaymentAvailable = (
    typesToCheck: (typeof PAYMENT_TYPES)[keyof typeof PAYMENT_TYPES][]
  ) =>
    allowedPaymentTypes.some((paymentType: PaymentType) =>
      typesToCheck.some(typeToCheck => typeToCheck === paymentType)
    );

  const isGooglePayAvailable = isPaymentAvailable([
    PAYMENT_TYPES.PAYU_GOOGLE_PAY,
  ]);
  const isPayNowAvailable = isPaymentAvailable([PAYMENT_TYPES.PAYNOW]);
  const isPayPoAvailable = isPaymentAvailable([
    PAYMENT_TYPES.PAYPO,
    PAYMENT_TYPES.PAYU_PAYPO,
  ]);
  const isStripeAvailable = isPaymentAvailable([
    PAYMENT_TYPES.STRIPE_LINK,
    PAYMENT_TYPES.STRIPE_CARD,
  ]);
  const isBlikAvailable = isPaymentAvailable([
    PAYMENT_TYPES.PAYU_BLIK,
    PAYMENT_TYPES.BLUE_MEDIA_BLIK,
  ]);
  const isMasterCardOrVisaAvailable = isPaymentAvailable([
    PAYMENT_TYPES.PAYU_CARD,
    PAYMENT_TYPES.BLUE_MEDIA_CARD,
    PAYMENT_TYPES.STRIPE_CARD,
  ]);
  const isPayUAvailable = isPaymentAvailable([
    PAYMENT_TYPES.PAYU,
    PAYMENT_TYPES.PAYU_CARD,
    PAYMENT_TYPES.PAYU_GOOGLE_PAY,
  ]);
  const isBlueMediaAvailable = isPaymentAvailable([
    PAYMENT_TYPES.BLUE_MEDIA,
    PAYMENT_TYPES.BLUE_MEDIA_CARD,
    PAYMENT_TYPES.BLUE_MEDIA_BLIK,
  ]);

  return {
    isPayPoAvailable,
    isPayUAvailable,
    isMasterCardOrVisaAvailable,
    isGooglePayAvailable,
    isBlueMediaAvailable,
    isStripeAvailable,
    isPayNowAvailable,
    isBlikAvailable,
  };
};

export default useAvailablePaymentProviders;
