import Select from 'react-select';
import { components } from 'react-select';
import kebabCase from 'lodash/kebabCase';
import { theme } from 'twin.macro';

import { isClient } from '@utils/helpers';

const InputSelect = ({
  name,
  styles = null,
  mergeStyles = {},
  disabled = false,
  customDisabledStyles = {},
  dataCy = '',
  ...restProps
}) => {
  const customStyles = styles || {
    control: (provided, { isDisabled }) => ({
      ...provided,
      padding: '4px 0 4px 20px',
      background: isDisabled
        ? customDisabledStyles?.background ?? theme`colors.gray.1`
        : provided.background,
      'border-color': isDisabled
        ? customDisabledStyles?.borderColor ?? theme`colors.gray.1`
        : provided['border-color'],
      cursor: isDisabled ? 'not-allowed' : provided.cursor,
    }),
    input: provided => ({
      ...provided,
      input: {
        '--tw-ring-shadow': 'transparent !important',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.3` : provided.color,
    }),
    placeholder: provided => ({
      ...provided,
      margin: 0,
    }),
    indicatorSeparator: (provided, { isDisabled }) => ({
      ...provided,
      'background-color': isDisabled
        ? theme`colors.gray.2`
        : provided['background-color'],
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.2` : provided.color,
    }),
    menuList: provided => ({
      ...provided,
      background: theme`colors.white`,
      zIndex: '9999',
    }),
    option: provided => ({
      ...provided,
      padding: '8px 20px',
      color: theme`colors.primary`,
      background: theme`colors.white`,
      '&:hover': {
        cursor: 'pointer',
        color: theme`colors.white`,
        background: theme`colors.primary`,
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 99 }),
    ...mergeStyles,
  };

  return (
    <Select
      className={`select--${kebabCase(name)}`}
      isSearchable={false}
      menuPortalTarget={isClient && document.querySelector('#selectPortal')}
      isDisabled={disabled}
      styles={customStyles}
      blurInputOnSelect
      components={
        dataCy && {
          Control: props => (
            <components.Control
              {...props}
              innerProps={{
                ...props.innerProps,
                'data-cy': dataCy,
              }}
            />
          ),
        }
      }
      theme={defaultTheme => ({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary25: theme`colors.primary-hover`,
          primary: theme`colors.primary`,
          primary50: theme`colors.primary-hover`,
          neutral50: 'red',
        },
      })}
      {...restProps}
    />
  );
};

InputSelect.displayName = 'InputSelect';

export default InputSelect;
