import useAvailablePaymentProviders from '@components/modules/Footer/hooks/useAvailablePaymentProviders';

const usePaymentsData = () => {
  const {
    isPayPoAvailable,
    isPayUAvailable,
    isMasterCardOrVisaAvailable,
    isGooglePayAvailable,
    isBlueMediaAvailable,
    isStripeAvailable,
    isPayNowAvailable,
    isBlikAvailable,
  } = useAvailablePaymentProviders();

  const paymentsData: { name: string; src: string; isAvailable: boolean }[] = [
    {
      name: 'Blik',
      src: '/images/footer/blik.svg',
      isAvailable: isBlikAvailable,
    },
    {
      name: 'Visa',
      src: '/images/footer/visa.svg',
      isAvailable: isMasterCardOrVisaAvailable,
    },
    {
      name: 'MasterCard',
      src: '/images/footer/mastercard.svg',
      isAvailable: isMasterCardOrVisaAvailable,
    },
    {
      name: 'GooglePay',
      src: '/images/payment/google_pay.svg',
      isAvailable: isGooglePayAvailable,
    },
    {
      name: 'PayPo',
      src: '/images/footer/pay po.svg',
      isAvailable: isPayPoAvailable,
    },
    {
      name: 'PayU',
      src: '/images/footer/pay u.svg',
      isAvailable: isPayUAvailable,
    },
    {
      name: 'BlueMedia',
      src: '/images/footer/blue media.svg',
      isAvailable: isBlueMediaAvailable,
    },
    {
      name: 'PayNow',
      src: '/images/footer/pay now.svg',
      isAvailable: isPayNowAvailable,
    },
    {
      name: 'Stripe',
      src: '/images/footer/stripe.svg',
      isAvailable: isStripeAvailable,
    },
  ];

  return paymentsData
    .filter(({ isAvailable }) => isAvailable)
    .map(({ name, src }) => ({ name, src }));
};

export default usePaymentsData;
